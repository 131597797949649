import React from "react";
import './style.css';
import cover from "../assets/gameCover.png"

const Home = () => {
    return <div className="content">
        <div className="section p-5 mb-4">
            <div className="container">
                <div className="row align-items-end">
                <div className="col-12 col-md-6">
                <h1 className="title">An all new 2D action-adventure game set in feudal Japan</h1>
                     <a>Blade of the Yokai is next game from Storm Eye Studios set to release
                        on Steam in Spring 2025. Play as Katsuro who must use both the tools
                        at his disposal and his wits to traverse dungeons and the world to 
                        save Japan and the sage, his mother, from demon lord Oden.
                    </a>
                    </div>
                    <div className="col-12 col-md-6">
                    <img className="image" src={cover} alt="office room"></img>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Home;
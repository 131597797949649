import React from "react";
import Phoenix from "../assets/phoenix.jpg"

const About = () => {
    return  <div className="content2">
            <div className="section p-5 mb-4">
                <div className="container">
                <div className="row align-items-end">
                    <div className="col-12 col-md-6">
                <h1 className="title">Our Story</h1>
                <a>Storm Eye Studios is an independent game developer
                     located in the Phoenix metro area in Arizona. This
                     studio works on projects utilizing the Godot engine. We 
                     hope to make games that will be enjoyed by many across the
                     world. Storm Eye Studios began as a dream between 
                    a group of friends who ended up starting an indie
                    game studio. Although there have been many challenges,
                    the team is dedicated to working with each other and 
                    making games that anyone can enjoy.
                </a>
                </div>
                <div className="col-12 col-md-6">
                <img className="pixel" src={Phoenix} alt="Cit of Phoenix, Arizona"></img>
                </div>
                </div>
                   
                </div>
            </div>
        </div>
};

export default About;